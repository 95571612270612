import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import Logout from './Logout';
import Error from './Error';

const Routes = createBrowserRouter([
    { path: '/:paramId', element: <App /> },
    { path: '/logout/:paramId', element: <Logout /> }, 
    { path: '/error', element: <Error /> }, 
]);

function AppRouter() {
    return <RouterProvider router={Routes} />;
}

export default AppRouter;
