// Error.js
import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>エラーが発生しました</h1>
      <p>再度ログインURLからアクセスしてください。</p>
      <br />
    </div>
  );
};

export default Error;
